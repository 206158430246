<template>
  <div>
    <full-height-wrapper v-if="isLoading">
      <loading-spinner></loading-spinner>
    </full-height-wrapper>

    <webform-header></webform-header>

    <fields-wrapper :rtl="rtl">
      <webform-response v-if="!!response"></webform-response>
      <webform-fields v-else></webform-fields>
    </fields-wrapper>

    <notification-message
      v-if="errorMessage"
      :message="errorMessage"
      @close="dismissErrorMessage"
    ></notification-message>
  </div>
</template>

<script>
import WebformHeader from "@/components/layout/WebformHeader";
import WebformFields from "@/components/layout/WebformFields";
import LoadingSpinner from "@/components/theme/LoadingSpinner";
import FullHeightWrapper from "@/components/theme/FullHeightWrapper";
import FieldsWrapper from "@/components/theme/FieldsWrapper";
import { mapMutations, mapState } from "vuex";
import WebformResponse from "@/components/layout/WebformResponse";
import NotificationMessage from "@/components/theme/NotificationMessage";
import get from "lodash.get";

export default {
  name: "ClueWebform",
  components: {
    NotificationMessage,
    WebformResponse,
    FieldsWrapper,
    FullHeightWrapper,
    LoadingSpinner,
    WebformFields,
    WebformHeader,
  },
  props: ["webformId", "gatewayUrl", "defaultLang", "recaptchaSiteKey"],
  computed: {
    ...mapState(["isLoading", "errorMessage", "rtl", "response"]),
  },
  async mounted() {
    if (this.webformId) {
      await this.setupWebformFromProps();
    } else {
      await this.setupWebformFromParentData();
    }
  },
  methods: {
    ...mapMutations(["dismissErrorMessage"]),
    async setupWebformFromProps() {
      this.$store.commit("setRecaptchaSiteKey", this.recaptchaSiteKey);
      await this.$store.dispatch("fetchWebformConfig", {
        webformPath: this.webformId,
        gatewayUrl: this.gatewayUrl,
        lang: this.defaultLang || "en",
      });
    },
    async setupWebformFromParentData() {
      this.$store.commit(
        "setRecaptchaSiteKey",
        get(this.$parent, "recaptchaSiteKey", "")
      );
      await this.$store.dispatch("fetchWebformConfig", {
        webformPath: get(this.$parent, "webformId"),
        gatewayUrl: get(this.$parent, "gatewayUrl"),
        lang: get(this.$parent, "defaultLang", "en"),
      });
    },
  },
};
</script>

<style scoped></style>
