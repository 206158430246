<template>
  <main class="pt-4 mb-6" :class="{ rtl }">
    <slot></slot>
  </main>
</template>

<script>
export default {
  name: "FieldsWrapper",
  props: ["rtl"],
};
</script>

<style scoped>
.rtl {
  direction: rtl;
}
</style>
