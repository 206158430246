<template>
  <div>
    <select
      :value="value"
      :id="id"
      :class="{ 'border-red-700': hasError }"
      :disabled="formDisabled || isLoading"
      @focus="$emit('reset')"
      @change="$emit('input', $event.target.value)"
      class="form-select block w-full mt-1"
    >
      <option v-if="field.templateOptions.placeholder" :value="undefined">
        {{ isLoading ? "Loading..." : field.templateOptions.placeholder }}
      </option>
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>
import { mapState } from "vuex";
import get from "lodash.get";

export default {
  name: "MenuField",
  props: ["value", "field", "id", "hasError"],
  data() {
    return {
      isLoading: true,
      options: [],
    };
  },
  computed: {
    ...mapState(["formDisabled", "currentGatewayUrl", "currentWebformPath"]),
  },
  async mounted() {
    const menuId = get(this.field, "menuId");
    if (menuId) {
      const url = `${this.currentGatewayUrl}/menus/${this.currentWebformPath}/${menuId}`;
      const response = await fetch(url);
      const { items } = await response.json();
      this.options = items;
      this.isLoading = false;
    }
  },
};
</script>

<style scoped>
:disabled {
  opacity: 0.5;
}
</style>
