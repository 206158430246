<template>
  <div class="mb-8 relative">
    <div
      class="relative"
      :aria-describedby="tooltip ? `${id}-tooltip` : undefined"
    >
      <label
        v-if="label"
        class="font-medium block"
        :for="id"
        :class="{ 'pr-6': !!tooltip, 'opacity-50': formDisabled }"
      >
        <span>{{ label }}</span>
        <span v-if="isRequired">
          <svg
            class="inline w-3 h-3 mb-2 fill-current text-red-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32.275 30.469"
          >
            <path
              d="M0 14.355l2.246-6.933c5.176 1.823 8.936 3.402 11.28 4.736-.62-5.892-.945-9.944-.977-12.158h7.08c-.098 3.223-.472 7.26-1.123 12.11 3.353-1.693 7.194-3.256 11.523-4.688l2.246 6.933c-4.134 1.368-8.186 2.28-12.158 2.735 1.986 1.725 4.785 4.801 8.399 9.228l-5.86 4.15c-1.888-2.57-4.118-6.07-6.69-10.497-2.408 4.59-4.524 8.089-6.347 10.498l-5.762-4.15c3.776-4.656 6.478-7.732 8.106-9.23-4.2-.813-8.187-1.724-11.963-2.734"
            />
          </svg>
          <span class="sr-only">required</span>
        </span>
      </label>

      <div class="absolute right-0 top-0" v-if="!!tooltip">
        <button
          type="button"
          class="focus:outline-none focus:shadow-outline rounded-full"
          @click="isTooltipOpen = true"
        >
          <svg
            width="20"
            height="20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9 15h2V9H9v6zm1-15C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM9 7h2V5H9v2z"
              fill="#110F24"
              fill-opacity=".4"
            ></path>
          </svg>
          <span class="sr-only">More Information</span>
        </button>
        <div v-show="isTooltipOpen">
          <div @click="isTooltipOpen = false" class="z-10 fixed inset-0"></div>
          <div
            class="
              z-20
              absolute
              right-0
              w-64
              text-white text-sm
              bg-gray-900 bg-opacity-100
              py-2
              px-3
              rounded
              transition
              ease-out
              duration-700
            "
          >
            <p
              :id="`${id}-tooltip`"
              v-html="$options.filters.sanitiser(tooltip)"
            ></p>
          </div>
        </div>
      </div>
    </div>
    <slot></slot>
    <div v-if="hasError" class="text-sm text-red-700">
      <p>{{ errorMessage }}</p>
    </div>
    <div v-if="description" class="text-sm mt-1">
      <p v-html="$options.filters.sanitiser(description)"></p>
    </div>
  </div>
</template>

<script>
import get from "lodash.get";
import { mapState } from "vuex";
import sanitiser from "@/filters/sanitiser";

export default {
  name: "FieldWrapper",
  data() {
    return {
      isTooltipOpen: false,
    };
  },
  props: ["field", "id", "hasError", "errorMessage", "validation"],
  filters: {
    sanitiser,
  },
  computed: {
    ...mapState(["formDisabled"]),
    isRequired() {
      if (!this.validation) {
        return false;
      }
      return Object.keys(this.validation).includes("required");
    },
    description() {
      return get(this.field, "templateOptions.description");
    },
    label() {
      return get(this.field, "templateOptions.label");
    },
    tooltip() {
      return get(this.field, "templateOptions.tooltip", "");
    },
  },
};
</script>

<style scoped></style>
