<template>
  <div>
    <hr class="my-8" />
    <h2 class="text-xl mb-4 font-medium">{{ field.templateOptions.label }}</h2>
    <div v-if="field.templateOptions.description" class="text-sm mt-1 mb-8">
      <p>{{ field.templateOptions.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionTitle",
  props: ["field"],
};
</script>

<style scoped></style>
