<template>
  <div class="fixed top-0 left-0 w-full">
    <div class="flex h-screen items-center justify-center z-50">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "FullHeightWrapper",
};
</script>

<style scoped></style>
