<template>
  <div class="max-w-lg mx-auto p-4">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MainWrapper",
  mounted() {
    document.body.className = "antialiased bg-gray-100 text-gray-900";
  },
};
</script>

<style scoped></style>
