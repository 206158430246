<template>
  <div><!--hidden field--></div>
</template>

<script>
export default {
  name: "HiddenField",
};
</script>

<style scoped></style>
