<template>
  <div class="mt-6">
    <button
      :disabled="formDisabled"
      class="w-32"
      v-if="!isLoading"
      type="submit"
    >
      {{ field.templateOptions.label }}
    </button>
    <span
      class="
        w-32
        text-center
        inline-block
        select-none
        px-4
        py-2
        rounded
        bg-gray-300
        text-gray-700
        font-bold
      "
      v-if="isLoading"
      >{{ submitMessage }}</span
    >
    <div v-if="hasError" class="text-red-800 flex mt-2 items-center">
      <svg
        class="fill-current w-5 h-5 mr-2 flex-shrink-0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path
          d="M2.93 17.07A10 10 0 1117.07 2.93 10 10 0 012.93 17.07zm12.73-1.41A8 8 0 104.34 4.34a8 8 0 0011.32 11.32zM9 5h2v6H9V5zm0 8h2v2H9v-2z"
        />
      </svg>
      <p>{{ errorMessage }}</p>
    </div>
    <p
      class="mt-2 text-xs text-gray-700 mb-4"
      v-if="field.templateOptions.description"
    >
      {{ field.templateOptions.description }}
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";
import get from "lodash.get";

export default {
  name: "SubmitButton",
  props: ["value", "field", "id", "hasError", "errorMessage"],
  computed: {
    ...mapState(["formDisabled", "isLoading"]),
    submitMessage() {
      return get(this.field, "templateOptions.submitMessage", "Submitting...");
    },
  },
};
</script>

<style scoped>
button {
  @apply px-4 py-2 rounded bg-blue-700 shadow text-white font-bold;
}

button:disabled {
  @apply opacity-50 cursor-default;
}

button:not(:disabled):hover {
  @apply bg-blue-700 shadow-none;
}
</style>
