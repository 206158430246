<template>
  <div class="mt-4 mb-2 flex justify-between items-center">
    <img v-if="logo" class="w-56" alt="Logo" :src="logo" />

    <locale-switcher
      v-if="locales.length > 1"
      :value="currentLocale"
      :locales="locales"
      @input="setLocale"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import LocaleSwitcher from "@/components/layout/LocaleSwitcher";

export default {
  name: "WebformHeader",
  components: { LocaleSwitcher },
  computed: {
    ...mapState(["currentLocale"]),
    ...mapGetters(["logo", "locales"]),
  },
  methods: {
    ...mapActions(["setLocale"]),
  },
};
</script>

<style scoped></style>
