<template>
  <div>
    <div v-if="files.length > 0" class="my-2">
      <div
        class="
          relative
          bg-gray-200
          mb-2
          rounded
          p-3
          flex
          justify-between
          items-center
        "
        v-for="(f, index) of files"
        :key="index"
      >
        <span class="flex items-center">
          <svg
            class="flex-shrink-0 w-5 h-5 fill-current text-gray-600"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M4 18h12V6h-4V2H4v16zm-2 1V0h12l4 4v16H2v-1z" />
          </svg>
          <span class="flex items-baseline">
            <span class="mx-4 text-sm max-w-64 overflow-hidden truncate">{{
              f.name
            }}</span>
            <span class="text-sm text-gray-600">{{ f.sizeDisplay }}</span>
          </span>
        </span>

        <div class="flex-1 text-center" v-if="f.hasError">
          <button
            type="button"
            @click="retryFile(index)"
            class="focus:outline-none focus:shadow-outline rounded"
          >
            <span class="p-2 hover:underline font-semibold">Retry</span>
          </button>
          <button
            type="button"
            @click="removeFile(index)"
            class="focus:outline-none focus:shadow-outline rounded"
          >
            <span class="p-2 hover:underline font-semibold">Remove</span>
          </button>
        </div>

        <div
          v-if="f.hasError"
          class=""
          type="button"
          @click="removeFile(index)"
        >
          <svg
            width="24"
            height="24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 3l9 18H3l9-18zm0 4l6 12H6l6-12zm-1 3h2v5h-2v-5zm0 8v-2h2v2h-2z"
              fill="#f6ad55"
              fill-opacity=".8"
            />
          </svg>
        </div>
        <div v-else-if="f.isDone" class="inline-flex items-center">
          <span class="p-2">Done</span>
          <svg
            width="24"
            height="24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.00016 16.1698l-4.17-4.17-1.42 1.41 5.59 5.59L21.0002 6.99984l-1.41-1.41L9.00016 16.1698z"
              fill="#63CE63"
            />
          </svg>
        </div>
        <div
          v-else-if="f.isUploading"
          class="
            inline-flex
            items-center
            focus:outline-none
            focus:shadow-outline
            rounded
          "
        >
          <span class="p-2">Uploading</span>
          <div
            class="
              loader
              ease-linear
              rounded-full
              border-gray-400 border-2 border-t-2
              h-5
              w-5
              mr-1
            "
          ></div>
        </div>
        <button
          v-else
          @click="removeFile(index)"
          type="button"
          class="
            inline-flex
            items-center
            focus:outline-none
            focus:shadow-outline
            rounded
          "
        >
          <span class="p-2 hover:underline font-semibold">Remove</span>
          <svg
            width="24"
            height="24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.0003 13.4146l-5.29311 5.2931c-.0504.047-.10086.0911-.15866.129-.1143.075-.24386.1261-.37857.1493-.05985.0104-.07525.0098-.13575.014h-.0683c-.06051-.0042-.07591-.0036-.13576-.014-.11225-.0193-.22096-.058-.32021-.1138-.07941-.0446-.15261-.1001-.21701-.1645-.19451-.1945-.30172-.4664-.29237-.7413.00315-.091.01876-.1816.04631-.2684.03445-.1085.08755-.211.15635-.3017.0367-.0484.04831-.0586.08971-.1029l5.29307-5.2931-5.29307-5.29313c-.0414-.04435-.05301-.05445-.08971-.1029-.0688-.09071-.1219-.19316-.15635-.30171-.04136-.13031-.05561-.26882-.04161-.40482.03536-.34412.25287-.65228.56528-.80084.08225-.0391.16981-.0669.25961-.0824.1122-.0193.22761-.0193.33981 0 .15721.02715.30722.09225.43432.18871.04846.0367.05856.0483.10291.0897L12.0003 10.586l5.2931-5.29309c.0443-.0414.0545-.053.1029-.0897.0907-.0688.1932-.12191.3017-.15636.1303-.04135.2689-.0556.4048-.0416.1133.0116.2244.04275.3272.09165.0823.03911.1591.08951.2278.14936.2607.22736.387.58278.3283.92369-.0271.15721-.0923.30721-.1887.43432-.0367.04845-.0483.05855-.0897.1029l-5.2931 5.29313 5.2931 5.2931c.0414.0443.053.0545.0897.1029.0688.0907.1219.1932.1564.3017.0275.0868.0431.1774.0462.2684.0094.2749-.0978.5468-.2923.7413-.0644.0644-.1376.1199-.217.1645-.0993.0558-.208.0945-.3202.1138-.0599.0104-.0753.0098-.1358.014h-.0683c-.0605-.0042-.0759-.0036-.1357-.014-.1347-.0232-.2643-.0743-.3786-.1493-.0579-.0379-.1083-.082-.1587-.129l-5.2931-5.2931z"
              fill="#110F24"
              fill-opacity=".4"
            />
          </svg>
        </button>

        <progress-bar
          v-if="f.isUploading && f.progress && f.progress !== 100"
          class="absolute bottom-0 left-0"
          :index="index"
          :progress-percent="f.progress"
        ></progress-bar>
      </div>
    </div>

    <!-- Button and file limit message -->
    <button
      type="button"
      class="mt-2 add-btn"
      @click="$refs.fileInput.click()"
      :disabled="formDisabled || files.length >= maxCount"
    >
      {{ placeholderText }}
    </button>
    <span
      :class="files.length >= maxCount ? 'text-gray-900 font-bold' : ''"
      class="ml-2 text-sm text-gray-700"
      >{{ fileCountLimitMessage }}</span
    >

    <!-- Error message -->
    <notification-message
      :message="errorFeedback"
      v-if="errorFeedback"
      @close="errorFeedback = ''"
    ></notification-message>

    <!-- Hidden HTML File Input -->
    <input
      :id="id"
      type="file"
      :accept="fileAccept"
      class="hidden"
      ref="fileInput"
      @change="onFile"
    />
  </div>
</template>

<script>
import NotificationMessage from "@/components/theme/NotificationMessage";
import ProgressBar from "@/components/theme/ProgressBar";
import get from "lodash.get";
import { mapState } from "vuex";

export default {
  name: "AttachmentsField",
  components: { ProgressBar, NotificationMessage },
  props: ["value", "field", "id", "hasError"],
  data() {
    return {
      errorFeedback: "",
    };
  },
  computed: {
    ...mapState(["formDisabled", "files"]),
    fileAccept() {
      return get(this.field, "templateOptions.accept", "image/*");
    },
    placeholderText() {
      return get(this.field, "templateOptions.placeholder", "Add file");
    },
    maxSize() {
      const value = get(this.field, "templateOptions.maxSize", "5242880");
      return parseInt(value, 10);
    },
    maxCount() {
      const value = get(this.field, "templateOptions.maxCount", "5");
      return parseInt(value, 10);
    },
    fileCountLimitMessage() {
      const message = get(
        this.field,
        "templateOptions.fileCountLimitMessage",
        "[FileCountLimit] file limit"
      );
      return message.replace("[FileCountLimit]", this.maxCount);
    },
    maxFileSizeMessage() {
      const message = get(
        this.field,
        "templateOptions.maxFileSizeMessage",
        "Unable to upload file - Exceeds [MaxFileSize] file size limit"
      );
      return message.replace("[MaxFileSize]", this.formatBytes(this.maxSize));
    },
    invalidTypeMessage() {
      return get(
        this.field,
        "templateOptions.invalidTypeMessage",
        "Invalid file type"
      );
    },
  },
  methods: {
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
    },
    async removeFile(i) {
      this.$store.commit("removeFile", i);
      this.$emit("input", this.files.length);
      this.$refs.fileInput.value = null;
    },
    async retryFile(i) {
      await this.$store.dispatch("sendAttachment", i);
      if (this.$store.state.hasSubmitted && this.$store.getters.allFilesDone) {
        const { response, form } = this.$store.state;
        const { webformId } = form;
        await this.$router.push({
          name: "confirm",
          params: { id: webformId, response, form },
        });
      }
    },
    onFile(e) {
      this.errorFeedback = "";
      const { files } = e.target;
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];

        if (!this.checkNumberOfFiles()) {
          break;
        }

        if (!this.checkFileSize(file)) {
          break;
        }

        if (!this.checkFileIsAllowed(file)) {
          break;
        }

        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = ({ target }) => {
            this.addFileCallback(file, target.result);
          };
          reader.readAsBinaryString(file);
        } else {
          // readAsBinaryString is not available in IE11
          reader.onload = ({ target }) => {
            let binary = "";
            const bytes = new Uint8Array(target.result);
            const length = bytes.byteLength;
            for (let j = 0; j < length; j += 1) {
              binary += String.fromCharCode(bytes[j]);
            }
            this.addFileCallback(file, binary);
          };
          reader.readAsArrayBuffer(file);
        }
      }
      this.$refs.fileInput.value = null;
    },
    addFileCallback(file, binary) {
      this.$store.commit("addFile", {
        name: file.name,
        type: file.type,
        size: file.size,
        sizeDisplay: this.formatBytes(file.size),
        data: btoa(binary),
      });
      this.$emit("input", this.files.length);
    },
    checkFileSize(file) {
      if (file.size >= this.maxSize) {
        this.errorFeedback = this.maxFileSizeMessage;
        return false;
      }
      return true;
    },
    checkFileIsAllowed(file) {
      const allowed = this.fileAccept
        .split(",")
        .map((str) => str.trim().toLowerCase())
        .filter((typeOrExtension) => {
          if (
            file.type.toLowerCase().includes(typeOrExtension.replace("*", ""))
          ) {
            return true;
          }
          return file.name.toLowerCase().endsWith(typeOrExtension);
        });
      if (allowed.length === 0) {
        this.errorFeedback = this.invalidTypeMessage;
        return false;
      }
      return true;
    },
    checkNumberOfFiles() {
      if (this.files.length >= this.maxCount) {
        // exit as there are already the maximum number of files
        this.errorFeedback = this.fileCountLimitMessage;
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.add-btn {
  @apply px-4 py-2 rounded border border-blue-500 shadow text-blue-700 font-bold;
}

.add-btn:disabled {
  @apply bg-gray-100 border-gray-100 text-gray-600 cursor-default;
}

.add-btn:focus {
  @apply outline-none shadow-outline;
}

.loader {
  border-top-color: #90cdf4;
  -webkit-animation: spinner 1s linear infinite;
  animation: spinner 1s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
