<template>
  <div
    class="bg-blue-300 rounded h-1 transition-all duration-1000"
    :style="{ width }"
  ></div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: ["index", "progressPercent"],
  computed: {
    width() {
      return `${this.progressPercent}%`;
    },
  },
};
</script>

<style scoped></style>
