import { email, required } from "vuelidate/lib/validators";

export default function (validations) {
  const validators = {
    required,
    email,
  };
  return Object.keys(validations || {}).reduce((a, key) => {
    // eslint-disable-next-line no-param-reassign
    a[key] = validations[key].reduce((vA, vKey) => {
      // eslint-disable-next-line no-param-reassign
      vA[vKey] = validators[vKey];
      return vA;
    }, {});
    return a;
  }, {});
}
