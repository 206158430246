<template>
  <div>
    <h1 class="form-title font-medium text-2xl">{{ title }}</h1>
    <p class="form-description mt-2">{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: "WebformTitle",
  props: ["title", "description"],
};
</script>

<style scoped></style>
