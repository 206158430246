<template>
  <div>
    <webform-title
      class="mb-6"
      :title="title"
      :description="description"
    ></webform-title>

    <form @submit.prevent="onSubmit">
      <formival-form
        :fields="fields"
        :value="model"
        @input="setModel"
        :validation="$v.model"
      ></formival-form>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import WebformTitle from "@/components/theme/WebformTitle";
import debounce from "lodash.debounce";
import get from "lodash.get";

export default {
  name: "WebformFields",
  components: { WebformTitle },
  computed: {
    ...mapState(["model", "fields"]),
    ...mapGetters(["title", "description", "validationRules"]),
  },
  methods: {
    setModel: debounce(function (newModel) {
      this.$store.commit("setModel", newModel);
    }, 300),
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store.commit("setIsLoading", true);
      try {
        await this.$store.dispatch("submitWebform");
      } catch (error) {
        const message = get(error, "response.data.title", error.message);
        this.$store.commit("setErrorMessage", message);
        window.scrollTo(0, 0);
      } finally {
        this.$store.commit("setIsLoading", false);
      }
    },
  },
  validations() {
    return {
      model: this.validationRules,
    };
  },
};
</script>

<style scoped></style>
