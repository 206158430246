<template>
  <input
    :value="value"
    :id="id"
    :type="inputType"
    @change="$emit('touched')"
    class="form-input mt-1 block w-full"
    @blur="$emit('touched')"
    :disabled="formDisabled"
    @focus="$emit('reset')"
    :class="{ 'border-red-200': hasError }"
    :placeholder="field.templateOptions.placeholder"
    @input="$emit('input', $event.target.value)"
  />
</template>

<script>
import { mapState } from "vuex";
import get from "lodash.get";

export default {
  name: "InputField",
  props: ["value", "field", "id", "hasError"],
  computed: {
    ...mapState(["formDisabled"]),
    inputType() {
      return get(this.field, "templateOptions.type", "text");
    },
  },
};
</script>

<style scoped></style>
