import Vue from "vue";
import Vuex from "vuex";
import get from "lodash.get";
import pick from "lodash.pick";
import difference from "lodash.difference";
import mapValidationRules from "./map-validation-rules";
import conditionalFieldsFilter from "../filters/conditional-fields-filter";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: false,
    rtl: false,
    formDisabled: false,
    currentLocale: "en",
    errorMessage: "",
    currentWebformPath: "",
    currentGatewayUrl: "",
    recaptchaSiteKey: "",
    model: {},
    files: [],
    fields: [],
    form: {
      webformId: "",
      fields: [],
    },
    hasSubmitted: false,
    uploadFileIndex: 0,
    token: null,
    response: null,
  },
  getters: {
    webformId: (state) => get(state, "form.webformId", ""),
    title: (state) => get(state, "form.title", ""),
    description: (state) => get(state, "form.description", ""),
    logo: (state) => get(state, "form.logo", ""),
    locales: (state) =>
      Object.entries(get(state, "form.locales", {})).map((value) => ({
        id: value[0],
        name: value[1],
      })),
    validationRules: (state) => {
      const validKeys = state.fields
        .filter(({ type }) => type !== "hidden")
        .map(({ key }) => key);
      const validations = get(state, "form.validations", {});
      return mapValidationRules(pick(validations, ...validKeys));
    },
    allFilesDone: (state) => state.files.filter((f) => !f.isDone).length === 0,
    confirmMessageTemplate: (state) =>
      get(state, "form.help.confirm", "{{ response }}"),
  },
  mutations: {
    setFormDisabled(state, status) {
      state.formDisabled = status;
    },
    setWebformConfig(state, form) {
      state.form = form;
    },
    setRecaptchaSiteKey(state, key) {
      state.recaptchaSiteKey = key;
    },
    setIsLoading(state, status) {
      state.isLoading = status;
    },
    setCurrentLocale(state, lang) {
      state.currentLocale = lang;
      state.rtl = ["ar", "he", "fa", "ur", "sd"].includes(lang);
    },
    setCurrentPath(state, { webformPath, gatewayUrl }) {
      state.currentGatewayUrl = gatewayUrl;
      state.currentWebformPath = webformPath;
    },
    clearErrorMessage(state) {
      state.errorMessage = "";
    },
    dismissErrorMessage(state) {
      state.errorMessage = "";
    },
    setErrorMessage(state, message) {
      state.errorMessage = message;
    },
    setModel(state, newModel) {
      state.model = newModel;
      // Limit the number of iterations here to 10.
      // This is to prevent infinite loops from mutually dependent conditional fields
      for (let i = 0; i < 10; i++) {
        state.fields = conditionalFieldsFilter(
          get(state, "form.fields", []),
          get(state, "model", {})
        );
        let validKeys = state.fields
          .filter((f) => f && f.type !== "hidden")
          .map((f) => f.key)
          .filter(Boolean);
        if (difference(Object.keys(state.model), validKeys).length === 0) {
          break;
        }
        state.model = pick(state.model, validKeys);
      }
    },
    addFile(state, file) {
      state.files.push({
        ...file,
        isDone: false,
        isUploading: false,
        progress: 0,
        hasError: false,
        // TODO: track cancellation token of upload
      });
    },
    clearFiles(state) {
      state.files.length = 0;
    },
    removeFile(state, i) {
      state.files.splice(i, 1);
      // state.fileErrors = state.fileErrors.filter(e => e.fileIndex !== i);
      // TODO: if form has submitted and last file is removed then change to confirmation state
    },
    setUploadPercentage(state, { fileIndex, value }) {
      const file = state.files[fileIndex];
      file.progress = value;
      Vue.set(state.files, fileIndex, file);
    },
    setUploadFileIndex(state, index) {
      state.uploadFileIndex = index;
    },
    clearFileError(state, fileIndex) {
      state.errorMessage = null;
      const file = state.files[fileIndex];
      file.hasError = false;
      Vue.set(state.files, fileIndex, file);
    },
    setUploading(state, { fileIndex, status }) {
      const file = state.files[fileIndex];
      file.isUploading = status;
      Vue.set(state.files, fileIndex, file);
    },
    setFileDone(state, fileIndex) {
      const file = state.files[fileIndex];
      file.isDone = true;
      Vue.set(state.files, fileIndex, file);
    },
    addFileError(state, { fileIndex, error }) {
      state.errorMessage = error.message;
      const file = state.files[fileIndex];
      file.hasError = true;
      Vue.set(state.files, fileIndex, file);
    },
    setHasSubmitted(state, status) {
      state.hasSubmitted = status;
    },
    setResponse(state, response) {
      state.response = response;
    },
    setUploadToken(state, token) {
      state.token = token;
    },
  },
  actions: {
    async fetchWebformConfig({ commit }, { webformPath, gatewayUrl, lang }) {
      commit("setIsLoading", true);
      commit("clearErrorMessage");
      commit("setCurrentPath", { gatewayUrl, webformPath });
      const url = `${gatewayUrl}/webform/${webformPath}/${lang}`;
      const response = await fetch(url);
      if (response.status !== 200) {
        commit(
          "setErrorMessage",
          `${response.status}: ${response.statusMessage}`
        );
        commit("setIsLoading", false);
        return;
      }
      const data = await response.json();

      if (data.favicon) {
        const link =
          document.querySelector("link[rel*='icon']") ||
          document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "icon";
        const baseHref = (document.querySelector("base") || {}).href || "";
        link.href = `${baseHref}${data.favicon.replace(/^\//, "")}`;
        document.getElementsByTagName("head")[0].appendChild(link);
      }

      commit("setWebformConfig", data);
      commit("setModel", {});
      commit("setCurrentLocale", lang);
      commit("setIsLoading", false);
    },
    async setLocale({ state, dispatch }, lang) {
      if (lang === this.state.currentLocale) {
        return;
      }
      dispatch("fetchWebformConfig", {
        webformPath: state.currentWebformPath,
        gatewayUrl: state.currentGatewayUrl,
        lang,
      });
    },
    async submitWebform({ commit, state, dispatch }) {
      // Disable the form while submitting
      commit("setFormDisabled", true);
      let token = "";
      let message = false;
      try {
        const webformId = get(state, "form.webformId");

        const now = new Date();
        const tzOffset = 0 - now.getTimezoneOffset() / 60;
        const tz = tzOffset > 0 ? `(GMT+${tzOffset})` : `(GMT${tzOffset})`;
        const { data } = await axios.post(
          "/response",
          {
            ...get(state, "model", {}),
            webformId: webformId,
            userAgent: navigator.userAgent || "unknown",
            browserDate:
              now && now.toLocaleString ? `${now.toLocaleString()}` : "",
            browserTimezone: tz,
          },
          {
            baseURL: state.currentGatewayUrl,
            withCredentials: false,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        token = get(data, "token");
        message = get(data, "message", "Webform Submitted");
      } catch (error) {
        commit("setFormDisabled", false);
        commit("setErrorMessage", "Webform Gateway Error");
        return false;
      }
      // Submit of main form data now complete
      // This flag is used to check if should redirect to confirm when removing files
      commit("setHasSubmitted", true);
      commit("setResponse", message);

      // Conditionally upload attachments to the submitted form
      // (using token from previous response)
      const formHasFiles =
        state.form.fields.filter(({ type }) => type === "attachments").length >
        0;
      const attachments = state.files;
      const hasAttachments =
        attachments && attachments.length && attachments.length > 0;
      if (formHasFiles && hasAttachments && token) {
        commit("setUploadToken", token);

        await dispatch("sendAllAttachments", attachments);

        commit("setUploadFileIndex", attachments.length);
        if (state.files.filter((f) => f.hasError).length === 0) {
          // if NO files have errors reenable the form because everything is fine
          commit("setFormDisabled", false);
        }
      } else {
        // nothing to upload so safe to reenable form
        commit("setFormDisabled", false);
      }

      if (!state.formDisabled) {
        // All submitted OK
        // return message;
        return true;
      }
      // Form is still disabled implies errors with attachments
      return false;
    },
    async sendAllAttachments({ dispatch }, attachments) {
      for (let fileIndex = 0; fileIndex < attachments.length; fileIndex += 1) {
        // It is fine to disable this lint rule here
        // https://eslint.org/docs/rules/no-await-in-loop#when-not-to-use-it
        // eslint-disable-next-line no-await-in-loop
        await dispatch("sendAttachment", fileIndex);
      }
    },
    async sendAttachment({ commit, state }, fileIndex) {
      const {
        token,
        form: { webformId },
        files,
      } = state;
      const { name, type, data } = files[fileIndex];

      const onUploadProgress = (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        commit("setUploadPercentage", { fileIndex, value: progress });
      };

      commit("setUploadPercentage", { fileIndex, value: 0 });
      commit("setUploadFileIndex", fileIndex);
      commit("clearFileError", fileIndex);
      commit("setUploading", { fileIndex, status: true });
      try {
        await axios.post(
          "/response/upload",
          {
            attachments: [{ name, type, data }],
            webformId: webformId,
          },
          {
            baseURL: state.currentGatewayUrl,
            withCredentials: false,
            onUploadProgress,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        commit("setFileDone", fileIndex);
      } catch (error) {
        commit("addFileError", { fileIndex, error });
      } finally {
        commit("setUploading", { fileIndex, status: false });
      }
    },
  },
  modules: {},
});
