<template>
  <div
    class="text-sm mb-8 template-content"
    v-html="$options.filters.sanitiser(template)"
  ></div>
</template>

<script>
import sanitiser from "@/filters/sanitiser";
import get from "lodash.get";

export default {
  name: "TemplateField",
  props: ["field"],
  filters: {
    sanitiser,
  },
  computed: {
    template() {
      return get(this.field, "templateOptions.description", "");
    },
  },
};
</script>

<style>
.template-content > a {
  @apply text-blue-700;
}
.template-content > a:hover {
  @apply underline;
}
.template-content hr {
  @apply my-8;
}
.template-content h2 {
  @apply text-xl mb-4;
}
.template-content p {
  @apply mb-2;
}
</style>
