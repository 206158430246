<template>
  <datepicker
    :value="value"
    :typeable="true"
    @change="$emit('touched')"
    @focus="$emit('reset')"
    @input="$emit('input', $event)"
    :id="id"
    :input-class="inputClass"
  >
    <span slot="afterDateInput" class="datepicker-icon">
      <svg
        class="w-6 h-6 fill-current text-gray-600"
        width="24"
        height="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19a2 2 0 002 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"
        />
      </svg>
    </span>
  </datepicker>
</template>

<script>
import Datepicker from "vuejs-datepicker";

export default {
  name: "DatepickerField",
  components: {
    Datepicker,
  },
  props: ["value", "field", "id", "hasError"],
  computed: {
    inputClass() {
      return `form-input mt-1 block w-full${
        this.hasError ? " border-red-700" : ""
      }`;
    },
  },
};
</script>

<style scoped>
.datepicker-icon {
  @apply absolute right-0 top-0 mr-2 mt-2 pointer-events-none;
}
.rtl .datepicker-icon {
  @apply right-auto left-0 ml-2;
}
</style>
