<template>
  <main-wrapper>
    <clue-webform
      v-if="webformId"
      :webform-id="webformId"
      :gateway-url="gatewayUrl"
      :default-lang="defaultLang"
      :recaptcha-site-key="recaptchaSiteKey"
    ></clue-webform>
  </main-wrapper>
</template>

<script>
import MainWrapper from "@/components/theme/MainWrapper";
import ClueWebform from "@/components/ClueWebform";
import get from "lodash.get";

export default {
  name: "App",
  components: { ClueWebform, MainWrapper },
  data() {
    return {
      webformId: "",
      gatewayUrl: "",
      defaultLang: "en",
      recaptchaSiteKey: "",
      basePath: "/",
    };
  },
  async mounted() {
    // Get the provided Gateway URL configuration
    this.gatewayUrl = get(this.$parent, "gatewayUrl");
    this.defaultLang = get(this.$parent, "defaultLang", "en");
    this.recaptchaSiteKey = get(this.$parent, "recaptchaSiteKey", "");
    if (document.getElementsByTagName('base').length > 0) {
      const baseUrl = document.getElementsByTagName('base')[0].href || "/";
      this.basePath = new URL(baseUrl).pathname;
    }
    this.basePath = get(this.$parent, "basePath", this.basePath);

    // Get the Webform ID from the URL to pass to the main Clue Webform Component
    let { pathname } = window.location;
    if (pathname.startsWith(this.basePath)) {
      pathname = pathname.slice(this.basePath.length);
    }
    const pathnameParts = pathname.split("/") || [];
    this.webformId = pathnameParts[0];
  },
};
</script>

<style></style>
