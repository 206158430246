<template>
  <field-wrapper
    id="g-recaptcha-response"
    :field="field"
    :has-error="hasError"
    :error-message="errorMessage"
  >
    <div class="relative">
      <vue-recaptcha
        class="mt-6"
        :sitekey="recaptchaSiteKey"
        @verify="validateCaptcha"
        :loadRecaptchaScript="true"
      ></vue-recaptcha>
      <div
        v-if="formDisabled"
        class="absolute inset-0 bg-gray-200 rounded"
      ></div>
    </div>
  </field-wrapper>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";

import FieldWrapper from "@/components/form-controls/FieldWrapper";
import { mapState } from "vuex";
export default {
  name: "RecaptchaField",
  components: { FieldWrapper, VueRecaptcha },
  props: ["value", "field", "id", "hasError", "errorMessage"],
  computed: {
    ...mapState(["recaptchaSiteKey", "formDisabled"]),
  },
  methods: {
    validateCaptcha(response) {
      this.$emit("input", response);
    },
  },
};
</script>

<style scoped></style>
