<template>
  <div class="fixed top-0 left-0 right-0">
    <div class="mx-auto max-w-lg relative">
      <div
        class="
          shake
          bg-red-100
          border border-red-400
          text-red-700
          px-4
          py-3
          pr-16
          rounded
          mt-3
        "
        role="alert"
      >
        <span>{{ message }}</span>
        <button
          @click="$emit('close')"
          type="button"
          class="
            absolute
            top-0
            bottom-0
            right-0
            px-4
            py-3
            focus:outline-none
            focus:shadow-outline
            rounded
          "
        >
          <svg
            class="fill-current h-6 w-6 text-red-500"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.0003 13.4146l-5.29311 5.2931c-.0504.047-.10086.0911-.15866.129-.1143.075-.24386.1261-.37857.1493-.05985.0104-.07525.0098-.13575.014h-.0683c-.06051-.0042-.07591-.0036-.13576-.014-.11225-.0193-.22096-.058-.32021-.1138-.07941-.0446-.15261-.1001-.21701-.1645-.19451-.1945-.30172-.4664-.29237-.7413.00315-.091.01876-.1816.04631-.2684.03445-.1085.08755-.211.15635-.3017.0367-.0484.04831-.0586.08971-.1029l5.29307-5.2931-5.29307-5.29313c-.0414-.04435-.05301-.05445-.08971-.1029-.0688-.09071-.1219-.19316-.15635-.30171-.04136-.13031-.05561-.26882-.04161-.40482.03536-.34412.25287-.65228.56528-.80084.08225-.0391.16981-.0669.25961-.0824.1122-.0193.22761-.0193.33981 0 .15721.02715.30722.09225.43432.18871.04846.0367.05856.0483.10291.0897L12.0003 10.586l5.2931-5.29309c.0443-.0414.0545-.053.1029-.0897.0907-.0688.1932-.12191.3017-.15636.1303-.04135.2689-.0556.4048-.0416.1133.0116.2244.04275.3272.09165.0823.03911.1591.08951.2278.14936.2607.22736.387.58278.3283.92369-.0271.15721-.0923.30721-.1887.43432-.0367.04845-.0483.05855-.0897.1029l-5.2931 5.29313 5.2931 5.2931c.0414.0443.053.0545.0897.1029.0688.0907.1219.1932.1564.3017.0275.0868.0431.1774.0462.2684.0094.2749-.0978.5468-.2923.7413-.0644.0644-.1376.1199-.217.1645-.0993.0558-.208.0945-.3202.1138-.0599.0104-.0753.0098-.1358.014h-.0683c-.0605-.0042-.0759-.0036-.1357-.014-.1347-.0232-.2643-.0743-.3786-.1493-.0579-.0379-.1083-.082-.1587-.129l-5.2931-5.2931z"
              fill="#c53030"
              fill-opacity=".8"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotificationMessage",
  props: ["message"],
};
</script>

<style scoped>
.shake {
  animation: shake 300ms 2 linear;
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translateX(10px);
  }
  25% {
    -webkit-transform: translateX(-10px);
  }
  50% {
    -webkit-transform: translateX(10px);
  }
  75% {
    -webkit-transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}

@keyframes shake {
  0% {
    transform: translateX(10px);
  }
  25% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
