import DOMPurify from "dompurify";

DOMPurify.addHook("afterSanitizeElements", (node) => {
  if (node.getAttribute && node.getAttribute("target") === "_self") {
    node.setAttribute("data-open-self", "self");
  }
  return node;
});

// Add a hook to make all links open a new window
DOMPurify.addHook("afterSanitizeAttributes", (node) => {
  if (node.getAttribute && node.getAttribute("data-open-self") === "self") {
    node.removeAttribute("data-open-self");
    node.setAttribute("target", "_self");
  }
  // set all elements owning target to target=_blank
  if ("target" in node) {
    if (node.getAttribute("target") !== "_self") {
      node.setAttribute("target", "_blank");
    }
    // prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
    node.setAttribute("rel", "noopener noreferrer");
  }
  // set non-HTML/MathML links to xlink:show=new
  if (
    !node.hasAttribute("target") &&
    (node.hasAttribute("xlink:href") || node.hasAttribute("href"))
  ) {
    node.setAttribute("xlink:show", "new");
  }
});

export default function (unsafeText) {
  return DOMPurify.sanitize(unsafeText, {
    ALLOWED_TAGS: ["b", "a", "br"],
  });
}
