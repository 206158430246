<template>
  <select
    :value="value"
    :id="id"
    :class="{ 'border-red-700': hasError }"
    :disabled="formDisabled"
    @focus="$emit('reset')"
    @change="$emit('input', $event.target.value)"
    class="form-select block w-full mt-1"
  >
    <option v-if="field.templateOptions.placeholder" :value="undefined">
      {{ field.templateOptions.placeholder }}
    </option>
    <option
      v-for="(option, index) in field.options"
      :key="index"
      :value="option.value"
    >
      {{ option.label }}
    </option>
  </select>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SelectField",
  props: ["value", "field", "id", "hasError"],
  computed: {
    ...mapState(["formDisabled"]),
  },
};
</script>

<style scoped></style>
