<template>
  <div>
    <template-field
      :field="{ templateOptions: { description: confirmationMessage } }"
    ></template-field>
  </div>
</template>

<script>
import TemplateField from "@/components/form-controls/TemplateField";
import { mapGetters, mapState } from "vuex";

export default {
  name: "WebformResponse",
  components: { TemplateField },
  computed: {
    ...mapState(["response"]),
    ...mapGetters(["confirmMessageTemplate"]),
    confirmationMessage() {
      return this.confirmMessageTemplate
        .replace("{{ response }}", this.response)
        .replace("{{ URN }}", this.response)
        .replace("{{response}}", this.response)
        .replace("{{URN}}", this.response);
    },
  },
};
</script>

<style scoped></style>
