import Vue from "vue";
import Formival from "formival";
import InputField from "./components/form-controls/InputField.vue";
import DatepickerField from "./components/form-controls/DatepickerField.vue";
import SelectField from "./components/form-controls/SelectField.vue";
import TextareaField from "./components/form-controls/TextareaField.vue";
import FieldWrapper from "./components/form-controls/FieldWrapper.vue";
import TemplateField from "./components/form-controls/TemplateField.vue";
import RedirectButton from "./components/form-controls/RedirectButton.vue";
import RecaptchaField from "./components/form-controls/RecaptchaField.vue";
import SubmitButton from "./components/form-controls/SubmitButton.vue";
import SectionTitle from "./components/form-controls/SectionTitle.vue";
import AttachmentsField from "./components/form-controls/AttachmentsField.vue";
import HiddenField from "./components/form-controls/HiddenField.vue";
import MenuField from "@/components/form-controls/MenuField";

Vue.use(Formival);

const types = [
  {
    name: "input",
    component: InputField,
    wrappers: ["field-wrapper"],
  },
  {
    name: "datepicker",
    component: DatepickerField,
    wrappers: ["field-wrapper"],
  },
  {
    name: "select",
    component: SelectField,
    wrappers: ["field-wrapper"],
  },
  {
    name: "menu",
    component: MenuField,
    wrappers: ["field-wrapper"],
  },
  {
    name: "textarea",
    component: TextareaField,
    wrappers: ["field-wrapper"],
  },
  {
    name: "template",
    component: TemplateField,
  },
  {
    name: "paragraph",
    component: TemplateField,
  },
  {
    name: "redirect",
    component: RedirectButton,
  },
  {
    name: "captcha",
    component: RecaptchaField,
  },
  {
    name: "submit",
    component: SubmitButton,
  },
  {
    name: "sectionTitle",
    component: SectionTitle,
  },
  {
    name: "attachments",
    component: AttachmentsField,
    wrappers: ["field-wrapper"],
  },
  {
    name: "hidden",
    component: HiddenField,
  },
];

const wrappers = [
  {
    name: "field-wrapper",
    component: FieldWrapper,
  },
];

const validationMessages = {
  required:
    "{{field.templateOptions && field.templateOptions.label}} is required",
  email: "{{value}} is not a valid email address",
};

const formival = new Formival({
  types,
  wrappers,
  validationMessages,
});

export default formival;
