import template from "lodash.template";
import set from "lodash.set";

export default function (fields, model) {
  if (!fields) {
    return fields;
  }
  Object.values(fields).forEach((v, i) => {
    if (v.conditions) {
      const conditionKeys = Object.keys(v.conditions);
      if (conditionKeys.length > 0) {
        conditionKeys.forEach((key) => {
          set(fields[i], key, template(`<%=${v.conditions[key]}%>`)({ model }));
        });
      }
    }
  });
  return fields;
}
