<template>
  <label>
    <select :value="value" @change="updateLocale">
      <option v-for="locale in locales" :key="locale.id" :value="locale.id">
        {{ locale.name }}
      </option>
    </select>
  </label>
</template>

<script>
export default {
  name: "LocaleSwitcher",
  props: ["value", "locales"],
  methods: {
    updateLocale(e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>
