<template>
  <textarea
    :value="value"
    :id="id"
    class="form-textarea mt-1 block w-full"
    :class="{ 'border-red-200': hasError }"
    rows="3"
    :disabled="formDisabled"
    @change="$emit('touched')"
    @blur="$emit('touched')"
    @focus="$emit('reset')"
    @input="$emit('input', $event.target.value)"
    :placeholder="field.templateOptions.placeholder"
  />
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TextareaField",
  props: ["value", "field", "id", "hasError"],
  computed: {
    ...mapState(["formDisabled"]),
  },
};
</script>

<style scoped></style>
