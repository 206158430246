import Vue from "vue";
import Vuelidate from "vuelidate";
import App from "./App.vue";
import formival from "./formival";
import store from "./store";
import "./assets/tailwind.css";

Vue.use(Vuelidate);
Vue.config.productionTip = false;

((el) => {
  new Vue({
    formival,
    store,
    render: (h) => h(App),
    data: () => Object.assign({}, el.dataset),
  }).$mount(el);
})(document.getElementById("app"));
